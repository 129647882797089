import { logError } from '@tomra/datadog-browser-logging'
import React, { useState } from 'react'
import { Accordion, FileUpload, Label } from '../'
import { THEME } from '../../../lib'
import { fetchOrgNameByAbn, uploadFile } from '../../../services'

const { name } = THEME

type Props = {
  charityId: string
  uiFields: CharityUiFields
  onUiFieldsChange: (uiFields: Partial<CharityUiFields>) => void
  organization: Organization
  onOrganizationFieldsChange: (organization: Partial<Organization>) => void
}

export const Details = ({ charityId, uiFields, onUiFieldsChange, organization, onOrganizationFieldsChange }: Props) => {
  const [abnLookUpRequestStatus, setAbnLookUpRequestStatus] = useState<RequestStatus>('initial')
  const [abnFileRequestStatus, setAbnFileRequestStatus] = useState<RequestStatus>('initial')
  const [incorporationFileRequestStatus, setIncorporationFileRequestStatus] = useState<RequestStatus>('initial')
  const [localGroupFileRequestStatus, setLocalGroupFileRequestStatus] = useState<RequestStatus>('initial')
  const [nameEditable, setNameEditable] = useState(false)
  const isUploading =
    abnFileRequestStatus === 'loading' ||
    incorporationFileRequestStatus === 'loading' ||
    localGroupFileRequestStatus === 'loading'

  const onAbnBlur = async () => {
    try {
      if (organization.organizationAbn === '') {
        return
      }

      setAbnLookUpRequestStatus('loading')
      const { name } = await fetchOrgNameByAbn(organization.organizationAbn)
      setAbnLookUpRequestStatus('initial')
      onOrganizationFieldsChange({ name })
      setNameEditable(false)
    } catch (_error: any) {
      setAbnLookUpRequestStatus('initial')
      onOrganizationFieldsChange({ name: '' })
      setNameEditable(true)
    }
  }

  const onAbnFileUpload = async (abnFile: File) => {
    setAbnFileRequestStatus('loading')

    try {
      await uploadFile(abnFile, charityId, 'ABNDocument')
      setAbnFileRequestStatus('initial')
      onUiFieldsChange({ ABNDocument: { filename: abnFile.name, uploadedAt: new Date().toISOString() } })
    } catch (error: any) {
      setAbnFileRequestStatus('failed')
      logError(new Error('Failed to upload ABNDocument file'), error)
    }
  }

  const onIncorporationFileUpload = async (incorporationFile: File) => {
    setIncorporationFileRequestStatus('loading')

    try {
      await uploadFile(incorporationFile, charityId, 'IncorporationFile')
      setIncorporationFileRequestStatus('initial')
      onUiFieldsChange({
        IncorporationFile: { filename: incorporationFile.name, uploadedAt: new Date().toISOString() }
      })
    } catch (error: any) {
      setIncorporationFileRequestStatus('failed')
      logError(new Error('Failed to upload IncorporationFile file'), error)
    }
  }

  const onLocalGroupFileUpload = async (parentOrganizationLetterFile: File) => {
    setLocalGroupFileRequestStatus('loading')

    try {
      await uploadFile(parentOrganizationLetterFile, charityId, 'ParentOrganizationLetter')
      setLocalGroupFileRequestStatus('initial')
      onUiFieldsChange({
        ParentOrganizationLetter: { filename: parentOrganizationLetterFile.name, uploadedAt: new Date().toISOString() }
      })
    } catch (error: any) {
      setLocalGroupFileRequestStatus('failed')
      logError(new Error('Failed to upload ParentOrganizationLetter file'), error)
    }
  }

  return (
    <Accordion title="Details Of Your Organisation" locked={isUploading}>
      <div className="flex items-end space-x-md">
        <div className="input-field flex-1">
          <Label htmlFor="organizationAbn">ABN</Label>

          <input
            type="text"
            id="organizationAbn"
            value={organization.organizationAbn}
            className="input-text inverted"
            onChange={evt => onOrganizationFieldsChange({ organizationAbn: evt.target.value })}
            onBlur={onAbnBlur}
            disabled={name === 'CDSVICWEST' || abnLookUpRequestStatus === 'loading'}
            required
          />
        </div>
        {abnLookUpRequestStatus === 'loading' && <div className="loading-infinity w-8 mb-3 ml-4" />}
      </div>

      <div className="input-field">
        <Label htmlFor="name">Organisation name</Label>

        <input
          type="text"
          id="name"
          value={organization.name}
          className="input-text inverted"
          onChange={evt => onOrganizationFieldsChange({ name: evt.target.value })}
          disabled={!nameEditable}
          placeholder={abnLookUpRequestStatus === 'loading' ? 'Searching...' : ''}
          aria-disabled={!nameEditable}
          required
        />
      </div>

      <FileUpload
        id="ABNDocument"
        description="Copy of your ABN certificate"
        uploadedFile={uiFields.ABNDocument}
        attachmentErrors={uiFields.attachmentErrors}
        onChange={onAbnFileUpload}
        requestStatus={abnFileRequestStatus}
        acceptedFileTypes="image/png, image/jpeg, image/jpg, application/pdf"
      >
        <Label htmlFor="abnFile">Please submit a recent copy of your ABN (Pdf)</Label>
      </FileUpload>

      {organization.legalStructure === 'NON_PROFIT_UNDER_AIA2009' && (
        <>
          <div className="input-field">
            <Label htmlFor="incorporationNumber">Incorporation number</Label>

            <input
              type="text"
              id="incorporationNumber"
              className="input-text inverted"
              value={organization.incorporationNumber}
              onChange={evt => onOrganizationFieldsChange({ incorporationNumber: evt.target.value })}
              required
            />
          </div>

          <FileUpload
            description="Incorporation file (inc. association certificate)"
            id="IncorporationFile"
            uploadedFile={uiFields.IncorporationFile}
            attachmentErrors={uiFields.attachmentErrors}
            onChange={onIncorporationFileUpload}
            requestStatus={incorporationFileRequestStatus}
            acceptedFileTypes="image/png, image/jpeg, image/jpg, application/pdf"
          >
            <Label>Upload incorporation file (inc. association certificate)</Label>
          </FileUpload>
        </>
      )}

      <div className="flex items-center space-x-2">
        <input
          id="abnBelongsToParent"
          type="checkbox"
          className="checkbox"
          checked={uiFields.abnBelongsToParent}
          onChange={evt => onUiFieldsChange({ abnBelongsToParent: evt.target.checked })}
        />
        <label htmlFor="abnBelongsToParent">Tick if the ABN provided above belongs to your parent organisation</label>
      </div>

      {uiFields.abnBelongsToParent && (
        <>
          <div className="input-field">
            <Label htmlFor="localGroupName">Name of your local group</Label>

            <input
              type="text"
              id="localGroupName"
              className="input-text inverted"
              value={organization.localGroupName}
              onChange={evt => onOrganizationFieldsChange({ localGroupName: evt.target.value })}
              required
            />
          </div>

          <FileUpload
            description="Parent Organization Letter"
            id="ParentOrganizationLetter"
            uploadedFile={uiFields.ParentOrganizationLetter}
            attachmentErrors={uiFields.attachmentErrors}
            onChange={onLocalGroupFileUpload}
            requestStatus={localGroupFileRequestStatus}
            acceptedFileTypes="image/png, image/jpeg, image/jpg, application/pdf"
          >
            <div>
              <p className="prose">
                If the ABN number stated above belongs to your parent organisation, you must provide a separate
                letter/statutory declaration. The parent organization letter must be signed by body Head Office
                confirming that the local branch applying is:
              </p>

              <ul className="list-disc list-inside my-6">
                <li>An affiliated member of the parent organisation</li>
                <li>Authorised to quote the ABN of its parent organisation</li>
                <li>&quot;not-for-profit&quot; and authorised to fundraise</li>
                <li>The contact name of the person applying is a legitimate member of the organisation</li>
              </ul>
            </div>
          </FileUpload>
        </>
      )}
    </Accordion>
  )
}
