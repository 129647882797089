import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Accordion, Label } from '../'
import logoPlaceholder from '../../../images/logo-placeholder.svg'
import logoAppPreview from '../../../images/logoAppPreview.png'
import { THEME } from '../../../lib'
import { FirebaseImageUpload } from '../FirebaseImageUpload'
import { benefitToReadableString } from './enumsToReadableStrings'

type Props = {
  charityId: string
  uiFields: CharityUiFields
  onUiFieldsChange: (uiFields: Partial<CharityUiFields>) => void
  organization: Organization
  onOrganizationFieldsChange: (organization: Partial<Organization>) => void
  logoUrl: string
  setLogoUrl: (logoUrl: string) => void
}

const { displayName } = THEME

export const Description = ({
  charityId,
  uiFields,
  onUiFieldsChange,
  organization,
  onOrganizationFieldsChange,
  logoUrl,
  setLogoUrl
}: Props) => {
  const [logoPreviewHasLoaded, setLogoPreviewHasLoaded] = useState(false)
  const [organizationLogoRequestStatus, setOrganizationLogoRequestStatus] = useState<RequestStatus>('initial')
  const { pathname } = useLocation()

  return (
    <Accordion title="About your organisation">
      <div className="grid grid-cols-2 gap-6">
        <div className="col-span-2 lg:col-span-1 flex flex-col space-y-6">
          <div className="input-field">
            <Label
              htmlFor="displayName"
              tooltipContent={`The actual name as it will be shown in ${displayName}. Note: TOMRA reserves the right to not use any name that is not the registered name of the organisation or a business name or trademark owned by the organisation.`}
            >
              Display name
            </Label>

            <input
              type="text"
              id="displayName"
              className="input-text inverted"
              value={organization.displayName}
              onChange={evt => onOrganizationFieldsChange({ displayName: evt.target.value })}
              required
            />
          </div>

          <div className="input-field">
            <Label htmlFor="websiteUrl">Website url</Label>

            <input
              type="text"
              id="websiteUrl"
              className="input-text inverted"
              value={organization.websiteUrl}
              pattern=".*\..*"
              onChange={evt => onOrganizationFieldsChange({ websiteUrl: evt.target.value })}
            />
          </div>

          <div className="select-field">
            <Label htmlFor="benefit">Main community benefit</Label>

            <select
              id="benefit"
              className="inverted"
              value={organization.benefit}
              onChange={(evt: any) => onOrganizationFieldsChange({ benefit: evt.target.value })}
              required
            >
              <option value="">Select one &or;</option>

              {Object.keys(benefitToReadableString).map(key => (
                <option value={key} key={key}>
                  {
                    // @ts-ignore
                    benefitToReadableString[key]
                  }
                </option>
              ))}
            </select>
          </div>

          <div className="input-field">
            <Label htmlFor="description">Description of your organisation</Label>

            <textarea
              id="description"
              className="input-text inverted resize-none"
              rows={5}
              placeholder="Provide a brief description about your organization, who you are, what you bring to the community, why these funds are important, how they will be spent, who will be benefiting from these donations"
              maxLength={500}
              value={organization.description}
              onChange={(evt: any) => onOrganizationFieldsChange({ description: evt.target.value })}
              required
            />
            <p className="ml-4 text-sm">{organization.description.length}/500 characters</p>
          </div>
        </div>

        <div className="col-span-2 lg:col-span-1">
          <div className="relative -mt-12">
            <img className="relative" src={logoAppPreview} alt="logo preview in app" />

            <div className="absolute mt-[4.3%] top-1/3 !w-1/3 aspect-square left-1/2 -translate-x-1/2 rounded-full border-2 overflow-hidden">
              {!logoPreviewHasLoaded && (
                <div className="absolute flex items-center justify-center w-full h-full">
                  <div className="loading-infinity w-20" />
                </div>
              )}

              <img
                onLoad={() => setLogoPreviewHasLoaded(true)}
                src={logoUrl || logoPlaceholder}
                style={{ objectFit: 'cover', opacity: logoPreviewHasLoaded ? 1 : 0 }}
                alt="logo preview"
              />
            </div>
          </div>

          {pathname === '/charity' && (
            <div>
              <FirebaseImageUpload
                charityId={charityId}
                organizationLogoRequestStatus={organizationLogoRequestStatus}
                setOrganizationLogoRequestStatus={setOrganizationLogoRequestStatus}
                uiFields={uiFields}
                onImageUploaded={(uiFields, logoUrl) => {
                  onUiFieldsChange(uiFields)
                  setLogoUrl(logoUrl)
                  setLogoPreviewHasLoaded(false)
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Accordion>
  )
}
