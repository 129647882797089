import React, { FunctionComponent, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { EditIcon, Modal } from '../../../../components/shared'
import { ProgressBar } from '../../../../components/shared/ProgressBar'
import { currencyFormatter } from '../../../../lib/campaign'
import { CampaignCardSkeleton } from './CampaignCardSkeleton'
import { CampaignEnd } from './CampaignEnd'
import { CampaignStart } from './CampaignStart'
import { ExtendCampaign } from './ExtendCampaign'

const campaignStatusMap: { [key: string]: string } = {
  NEW: 'Current campaign',
  ACTIVE: 'Current campaign',
  EXPIRED: 'Expired campaign',
  STOPPED: 'Stopped campaign'
}

type Props = {
  charity: Charity
  campaignWithStatistics: CampaignWithStatistics
}

export const CampaignCard: FunctionComponent<Props> = ({ charity, campaignWithStatistics }) => {
  const [showCreateOrCopy, setShowCreateOrCopy] = useState(false)

  const navigate = useNavigate()

  if (campaignWithStatistics === undefined || !charity) {
    return <CampaignCardSkeleton />
  }

  if (campaignWithStatistics === null) {
    return null
  }

  const { campaignId, name, status, financialTarget, startTime, endTime } = campaignWithStatistics.campaign
  const { totalDonated } = campaignWithStatistics.statistics

  const showEditButton = status !== 'EXPIRED' && status !== 'STOPPED'
  const showCopyButton = status === 'EXPIRED' || status === 'STOPPED'

  return (
    <>
      <div className="card p-4 grid lg:grid-cols-7 gap-x-4 items-start">
        {showEditButton ? (
          <div className="row-start-1 lg:col-start-7 lg:col-span-1 flex justify-end">
            <Link to={`/charity/${charity.id}/campaign/${campaignId}`} aria-label="Edit campaign">
              <EditIcon width="1.5rem" height="1.5rem" />
            </Link>
          </div>
        ) : showCopyButton ? (
          <div className="row-start-1 lg:col-start-7 lg:col-span-1 flex justify-end">
            <button className="btn primary btn-sm" onClick={() => setShowCreateOrCopy(true)}>
              Create new
            </button>
          </div>
        ) : null}

        <ExtendCampaign
          charityId={charity.id}
          campaignId={campaignId}
          startTime={startTime as string}
          endTime={endTime as string}
          status={status}
        />

        <div className="row-start-1 col-start-1 col-span-2">
          <div className="text-sm text-storm dark:text-rock">{campaignStatusMap[status]}</div>
          <div className="text-xl">{name}</div>
        </div>

        <div className="row-start-1 col-start-4">
          <CampaignStart status={status} startTime={startTime as string} />
        </div>

        <div className="row-start-1 col-start-5">
          <CampaignEnd status={status} endTime={endTime as string} />
        </div>

        {status !== 'NEW' && (
          <div className="row-start-2 col-span-7">
            <ProgressBar current={totalDonated} total={financialTarget} />
          </div>
        )}

        <div className="row-start-3 col-span-7 flex justify-between">
          {status === 'NEW' ? (
            <div>
              <div className="text-sm">Goal</div>
              <div className="md:flex-1">{currencyFormatter.format(financialTarget)}</div>
            </div>
          ) : (
            <>
              <div>{currencyFormatter.format(totalDonated)}</div>
              {currencyFormatter.format(financialTarget)}
            </>
          )}
        </div>

        {status === 'NEW' && (
          <div className="row-start-4 col-start-7 col-span-2 flex justify-end mt-4">
            <div className="chip text-sm !bg-fire-orange-lighter !text-fire-orange-darker">Pending approval</div>
          </div>
        )}

        {(campaignWithStatistics as CampaignWithStatistics & CampaignWithChanges)?.pendingChanges && (
          <div className="row-start-4 col-start-7 col-span-2 flex justify-end mt-4">
            <div className="chip text-sm !bg-fire-orange-lighter !text-fire-orange-darker">
              Changes pending approval
            </div>
          </div>
        )}
      </div>

      <Modal isOpen={showCreateOrCopy}>
        <div className="dialog">
          <div className="dialog-header">
            <h2>Copy previous campaign details?</h2>
          </div>
          <div className="dialog-footer">
            <button className="btn" onClick={() => navigate(`/charity/${charity.id}/campaign`)}>
              No, create a brand new one
            </button>
            <button
              className="btn primary"
              onClick={() => navigate(`/charity/${charity.id}/campaign?copy=${campaignId}`)}
            >
              Yes, copy previous campaign details
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}
