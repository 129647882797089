import { fetch as _fetch, fetchData as _fetchData, fetchRawResponse as _fetchRaw } from '@tomra/client-side-http-client'
import { logError } from '@tomra/datadog-browser-logging'
import { auth } from '../firebase'
import { THEME } from './environment'
import { removeItemFromWebStorage } from './webStorage'

const { stateShort, name } = THEME

const authentication = {
  fetchNewToken: async () => {
    try {
      const token = await auth.currentUser?.getIdToken(true)
      return token || Promise.reject('Unauthorized')
    } catch (error: any) {
      logError(new Error('Failed to fetch new token'), error)
      await removeItemFromWebStorage('lastAuthTimestamp')
      await auth.signOut()
      return Promise.reject('Unauthorized')
    }
  },
  getToken: async () => {
    try {
      const token = await auth.currentUser?.getIdToken()
      return token || ''
    } catch (_error) {
      return ''
    }
  }
}

const headers = { 'Charity-State': stateShort, 'Tomra-App-Context': name }

export const authenticatedFetchData = (url: string, options?: RequestInit) =>
  _fetchData(url, { ...options, headers: { ...options?.headers, ...headers } }, authentication)

export const authenticatedFetchRaw = (url: string, options?: RequestInit) =>
  _fetchRaw(url, { ...options, headers: { ...options?.headers, ...headers } }, authentication)

export const enrichedFetch = (url: string, options?: RequestInit) =>
  _fetch(url, { ...options, headers: { ...options?.headers, ...headers } }, undefined)
