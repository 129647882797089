import { addMonths, differenceInDays, isBefore } from 'date-fns'
import React, { useState, useEffect, useMemo, FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { MAX_CAMPAIGN_DAYS, MIN_CAMPAIGN_DAYS, MIN_CAMPAIGN_START_DATE, validateCampaign } from '../../lib/campaign'
import { findStateDifference } from '../../lib/stateHelpers'
import { useCampaignFormFields } from '../../reducers/campaignFormFieldsReducer'
import { Datepicker, Label } from '../shared'
import { CurrencyInputField } from '../shared/CurrencyInputField'
import { FormValidationError } from '../shared/FormValidationError'

type Props = {
  original: Campaign | null
  onSubmit: (campaign: Campaign) => void
  submitRequestStatus: RequestStatus
  submitError: string
}

export const CampaignForm: FunctionComponent<Props> = ({ original, onSubmit, submitRequestStatus, submitError }) => {
  const { campaignId: pathCampaignId } = useParams() as { charityId: string; campaignId?: string }
  const [duration, setDuration] = useState(MAX_CAMPAIGN_DAYS)
  const [formValidationErrors, setFormValidationErrors] = useState<FormValidationError[]>([])
  const [showErrors, setShowErrors] = useState(false)

  const { formValues, updateField } = useCampaignFormFields(original)

  const diff = useMemo(() => findStateDifference(original, formValues), [formValues])
  const campaignHasChanges = Object.keys(diff).length > 0

  useEffect(() => {
    setDuration(differenceInDays(new Date(formValues.endTime), new Date(formValues.startTime)))

    const errors = validateCampaign(formValues)
    setFormValidationErrors(errors as FormValidationError[])
  }, [formValues])

  return (
    <div className="max-w-screen-sm mx-auto">
      <form
        onSubmit={e => {
          e.preventDefault()

          const hasErrors = Object.keys(formValidationErrors).length > 0
          setShowErrors(hasErrors)

          if (campaignHasChanges && !hasErrors) {
            onSubmit(formValues)
          }
        }}
        className="card space-y-6 p-6 group"
        noValidate
      >
        <h1 className="text-3xl">{pathCampaignId ? 'Edit fundraising campaign' : 'Create fundraising campaign'}</h1>

        <div className="input-field">
          <Label htmlFor="name">Campaign name</Label>
          <input
            type="text"
            id="name"
            className="input-text"
            value={formValues.name}
            onChange={evt => updateField({ name: evt.target.value })}
            maxLength={60}
            required
          />
          <div className="flex justify-between items-end w-full mt-2">
            <div>{showErrors && <FormValidationError field="name" errors={formValidationErrors} />}</div>
            <div className="text-sm ml-4">{formValues.name.length}/60 characters</div>
          </div>
        </div>

        <div className="input-field">
          <Label
            htmlFor="description"
            tooltipContent="To help supporters engage with your campaign, be specific, transparent and make it relevant to your community. What are you raising money for? How will the funds raised benefit your community?"
          >
            Description
          </Label>

          <textarea
            id="description"
            placeholder="Example: Our organisation is raising funds to purchase items for the care and comfort of patients of our local Hospital, including Maternity, emergency and operating theatre patients."
            className="input-text resize-none"
            maxLength={500}
            value={formValues.description}
            onChange={evt => updateField({ description: evt.target.value })}
            required
          />
          <div className="flex justify-between items-end w-full mt-2">
            <div>{showErrors && <FormValidationError field="description" errors={formValidationErrors} />}</div>
            <p className="text-sm mr-2">{formValues.description.length}/500 characters</p>
          </div>
        </div>

        <div className="grid grid-cols-3 items-end gap-x-4">
          <div className="col-span-1 input-field">
            <Label
              htmlFor="startTime"
              tooltipContent="Setting a visible deadline is a powerful motivator for donors. But allow a reasonable amount of time to achieve your goals. Your deadline, too, should be ambitious but attainable."
            >
              Start date
            </Label>

            <Datepicker
              id="startTime"
              className={`input-text ${formValues.status !== 'NEW' ? 'cursor-not-allowed select-none' : ''}`}
              value={new Date(formValues.startTime)}
              onChange={date => {
                if (date) {
                  updateField({ startTime: date })
                }
              }}
              min={MIN_CAMPAIGN_START_DATE}
              disabled={formValues.status !== 'NEW'}
              required
            />
          </div>

          <div className="col-span-1 input-field">
            <Label
              htmlFor="endTime"
              tooltipContent="Minimum 3 months and maximum 1 year from the date you want to start your fundraising."
            >
              End date
            </Label>

            <Datepicker
              id="endTime"
              value={new Date(formValues.endTime)}
              className="input-text"
              onChange={date => {
                if (date) {
                  updateField({ endTime: date })
                }
              }}
              min={addMonths(MIN_CAMPAIGN_START_DATE, 1)}
              max={addMonths(MIN_CAMPAIGN_START_DATE, 12)}
              required
            />
          </div>

          <div
            className={`col-span-1 flex items-center space-x-1 text-sm mb-5 ${
              duration < MIN_CAMPAIGN_DAYS || duration > MAX_CAMPAIGN_DAYS ? 'text-warm-red-dark' : ''
            }`}
          >
            {duration > 0 && duration < MIN_CAMPAIGN_DAYS ? (
              <span>{duration} days is too short</span>
            ) : duration > 0 && duration > MAX_CAMPAIGN_DAYS ? (
              <span>{duration} days is too long</span>
            ) : null}
          </div>

          <div className="col-span-3 mt-2">
            {isBefore(formValues.endTime as Date, formValues.startTime as Date) ? (
              <div className="text-warm-red-dark ml-2">Start date must be before end date</div>
            ) : (
              <>
                <FormValidationError field="startTime" errors={formValidationErrors} />
                <FormValidationError field="endTime" errors={formValidationErrors} />
              </>
            )}
          </div>
        </div>

        <div>
          <Label
            htmlFor="financialTarget"
            tooltipContent="Set a target that is both ambitious and attainable (min $100 and max $1 mill). It will keep you motivated and will inspire your supporters and the community to help you reach it, just that little bit more. And remember one eligible bottle or can donated is worth 10 cents so set a target that is challenging, but not impossible to reach."
          >
            Financial target
          </Label>

          <CurrencyInputField
            value={!isNaN(formValues.financialTarget) ? formValues.financialTarget : 0}
            onChange={value => updateField({ financialTarget: value })}
          />

          {showErrors && <FormValidationError field="financialTarget" errors={formValidationErrors} />}
        </div>

        <div>
          <Label htmlFor="engagementPlan">Awareness and Engagement plan</Label>

          <textarea
            id="engagementPlan"
            className="input-text resize-none"
            placeholder="List activities that will raise awareness of your fundraising campaign, describe how you will engage with all your networks (staff, volunteers, supporters, local businesses, corporate donors, ...) and also engage the wider community to maximise the donations."
            maxLength={1000}
            value={formValues.engagementPlan}
            onChange={evt => updateField({ engagementPlan: evt.target.value })}
            required
          />
          <div className="flex justify-end items-end">
            <div className="text-sm mr-2">{formValues.engagementPlan.length}/1000 characters</div>
          </div>
        </div>

        <div className="flex items-center justify-end space-x-4">
          <button type="button" className="btn" onClick={() => window.location.assign('/')}>
            Cancel
          </button>

          <button
            className="btn primary w-full md:w-auto aria-disabled:bg-fog aria-disabled:shadow-none group-invalid:cursor-not-allowed h-3 flex space-x-4 items-center"
            aria-disabled={submitRequestStatus === 'loading' || formValidationErrors.length > 0 || !campaignHasChanges}
          >
            {submitRequestStatus === 'loading' ? (
              <span className="loading-infinity bg-white w-8" />
            ) : (
              <span>Submit for review</span>
            )}
          </button>
        </div>

        {submitRequestStatus === 'failed' && <p className="text-warm-red-dark text-right">{submitError}</p>}
      </form>
    </div>
  )
}
