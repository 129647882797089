import React, { FunctionComponent, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { THEME } from '../../lib'
import { NavIcon } from '../shared'

type Props = {
  isAuthenticated: 'yes' | 'no' | 'toBeDecided'
  logout: () => void
}

export const SmallScreenNavBar: FunctionComponent<Props> = ({ isAuthenticated, logout }) => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)

  const { pathname } = useLocation()

  useEffect(() => {
    if (isSideMenuOpen) {
      setIsSideMenuOpen(false)
    }
  }, [pathname])

  return (
    <nav className="menu lg:hidden">
      <ul className="px-6 2xl:px-0">
        <li className="!col-[1_/_span_9]">
          <a href="/" className="flex items-center space-x-4 text-lg">
            <img src={`themes/${THEME.logoUrl}`} className="h-[3rem]" alt={`${THEME.displayName} logo`} />
            <span className="text-lg">{THEME.displayName} Charity</span>
          </a>
        </li>

        {isAuthenticated && (
          <li className="relative flex items-center justify-end !col-[11_/_span_2]">
            <button
              aria-label="Open menu"
              className="btn-icon"
              onClick={() => setIsSideMenuOpen(prevState => !prevState)}
            >
              <NavIcon width="2rem" height="2rem" />
            </button>

            <ul className="w-[95vw] right-0">
              <li>
                <NavLink to="/">Dashboard</NavLink>
              </li>
              <li>
                <NavLink to="/tos">Terms and Conditions</NavLink>
              </li>
              <li>
                <NavLink to="/profile">Profile</NavLink>
              </li>
              <li className="w-full flex items-center justify-center mt-4">
                <button className="btn outlined" onClick={logout}>
                  Log out
                </button>
              </li>
            </ul>
          </li>
        )}
      </ul>
    </nav>
  )
}
