import { subYears } from 'date-fns'
import React from 'react'
import { Accordion, Datepicker, InfoTooltip, Label } from '../'
import { THEME } from '../../../lib'
import { legalStructureToReadableString } from './enumsToReadableStrings'

type Props = {
  organization: Organization
  onOrganizationFieldsChange: (organization: Partial<Organization>) => void
  uiFields: CharityUiFields
  onUiFieldsChange: (uiFields: Partial<CharityUiFields>) => void
}

const { stateShort } = THEME

export const Eligibility = ({ organization, onOrganizationFieldsChange, uiFields, onUiFieldsChange }: Props) => {
  const maxDate = subYears(new Date(), 2)

  const { registeredState, establishedDate, legalStructure, legalStructureDetails } = organization

  return (
    <Accordion title="Eligibility">
      <div className="select-field">
        <Label htmlFor="registeredState">Which state is your organisation registered at?</Label>

        <select
          id="registeredState"
          value={registeredState}
          className="inverted"
          onChange={({ target }) =>
            onOrganizationFieldsChange({
              registeredState: target.value
            })
          }
          required
        >
          <option value="">Select one &or;</option>
          <option value="NSW">NSW</option>
          <option value="NT">NT</option>
          <option value="QLD">QLD</option>
          <option value="VIC">VIC</option>
          <option value="TAS">TAS</option>
          <option value="ACT">ACT</option>
          <option value="WA">WA</option>
          <option value="SA">SA</option>
        </select>
      </div>

      <div className="input-field">
        <Label
          htmlFor="establishedDate"
          tooltipContent="To be eligible, your organisation must have been operating for 2 years"
        >
          When did your organisation start operating?
        </Label>

        <Datepicker
          id="establishedDate"
          value={new Date(establishedDate)}
          disabled={!registeredState}
          aria-disabled={!registeredState}
          className="input-text w-full inverted"
          onChange={date => {
            if (date) {
              onOrganizationFieldsChange({
                establishedDate: new Date(date).toISOString()
              })
            }
          }}
          max={maxDate}
          required
        />
      </div>

      <fieldset>
        <legend className="label">What Legal structure best describes your not-for-profit organisation?</legend>

        <ul className="radio-options">
          <li>
            <label htmlFor="legalStructure1" className="inverted">
              <input
                type="radio"
                id="legalStructure1"
                value="ACNC_CHARITY"
                name="legalStructure"
                checked={legalStructure === 'ACNC_CHARITY'}
                onChange={(evt: any) => {
                  if (evt.target.checked) {
                    onOrganizationFieldsChange({ legalStructure: evt.target.value })
                  }
                }}
                required
                readOnly
              />
              {legalStructureToReadableString[stateShort]['ACNC_CHARITY']}
            </label>
          </li>

          <li>
            <label htmlFor="legalStructure2" className="inverted">
              <input
                type="radio"
                id="legalStructure2"
                value="REGISTERED_SCHOOL"
                name="legalStructure"
                checked={legalStructure === 'REGISTERED_SCHOOL'}
                onChange={(evt: any) => {
                  if (evt.target.checked) {
                    onOrganizationFieldsChange({ legalStructure: evt.target.value })
                  }
                }}
                required
                readOnly
              />
              {legalStructureToReadableString[stateShort]['REGISTERED_SCHOOL']}
            </label>
          </li>

          <li className="flex space-x-4 items-center">
            <label htmlFor="legalStructure3" className="inverted">
              <input
                type="radio"
                id="legalStructure3"
                value="NON_PROFIT_UNDER_AIA2009"
                name="legalStructure"
                checked={legalStructure === 'NON_PROFIT_UNDER_AIA2009'}
                onChange={(evt: any) => {
                  if (evt.target.checked) {
                    onOrganizationFieldsChange({ legalStructure: evt.target.value })
                  }
                }}
                required
                readOnly
              />

              {legalStructureToReadableString[stateShort]['NON_PROFIT_UNDER_AIA2009']}
            </label>
            <InfoTooltip content="Note that sporting clubs must be affiliated with a recognised state sporting organisation" />
          </li>

          <li className="flex space-x-4 items-center">
            <label htmlFor="legalStructure4" className="inverted">
              <input
                type="radio"
                id="legalStructure4"
                value="NON_PROFIT_COMPANY"
                name="legalStructure"
                checked={legalStructure === 'NON_PROFIT_COMPANY'}
                onChange={(evt: any) => {
                  if (evt.target.checked) {
                    onOrganizationFieldsChange({ legalStructure: evt.target.value })
                  }
                }}
                required
                readOnly
              />
              {legalStructureToReadableString[stateShort]['NON_PROFIT_COMPANY']}
            </label>
            <InfoTooltip content="Note that sporting clubs must be affiliated with a recognised state sporting organisation" />
          </li>

          <li>
            <label htmlFor="legalStructure5" className="inverted">
              <input
                type="radio"
                id="legalStructure5"
                value="OTHER"
                name="legalStructure"
                checked={legalStructure === 'OTHER'}
                onChange={(evt: any) => {
                  if (evt.target.checked) {
                    onOrganizationFieldsChange({ legalStructure: evt.target.value })
                  }
                }}
                required
                readOnly
              />
              {legalStructureToReadableString[stateShort]['OTHER']}
            </label>
          </li>
        </ul>
      </fieldset>

      {legalStructure === 'OTHER' && (
        <div className="input-field">
          <Label htmlFor="legalStructureDetails">Please specify</Label>

          <input
            type="text"
            className="input-text inverted"
            id="legalStructureDetails"
            value={legalStructureDetails}
            onChange={evt => onOrganizationFieldsChange({ legalStructureDetails: evt.target.value })}
            required
          />
        </div>
      )}

      <div className="flex items-center space-x-2 ml-2">
        <input
          id="operatesInState"
          type="checkbox"
          className="checkbox mr-sm"
          checked={uiFields.operatesInState}
          onChange={evt => onUiFieldsChange({ operatesInState: evt.target.checked })}
          required
        />
        <label htmlFor="operatesInState">
          By ticking this box you are confirming that you currently operate in {stateShort}
        </label>
      </div>
    </Accordion>
  )
}
