import { logError } from '@tomra/datadog-browser-logging'
import { differenceInMilliseconds } from 'date-fns'
import { API_HOST, THEME, authenticatedFetchData, enrichedFetch } from '../lib'

export const triggerSendMagicLink = async (email: string) => {
  return enrichedFetch(`${API_HOST}/charities/v1/signin`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      url: `${window.location.href}?timestamp=${Date.now()}`,
      nativeAppName: THEME.name
    })
  }).run()
}

export const sendSignInMetric = (href: string) => {
  try {
    const durationMillis = getDurationMillis(getTimestampFromUrl(href))

    return authenticatedFetchData(`${API_HOST}/charities/v1/signin-metric/${durationMillis}`, {
      method: 'POST'
    })
      .run()
      .catch(error => {
        logError(new Error('Unable to send sign in metric'), error)
      })
  } catch (error: unknown) {
    logError(new Error('Unable to set sign in metric'), error as Error)
  }
}

export const trackSignInLink = (link: string) => {
  try {
    window?.DD_RUM?.addAction('sign_in_with_email_link_duration', {
      link,
      duration: getDurationMillis(getTimestampFromUrl(link))
    })
  } catch (error: unknown) {
    logError(new Error('Track sign in link failed'), error as Error)

    window?.DD_RUM?.addAction('sign_in_with_email_link', {
      link
    })
  }
}

const getDurationMillis = (timestamp: string) => {
  const startTimestamp = new Date(parseInt(timestamp))
  const completedTimestamp = Date.now()
  const durationMillis = differenceInMilliseconds(completedTimestamp, startTimestamp)

  if (Number.isNaN(durationMillis)) {
    throw new Error('duration is not a valid number')
  }

  return durationMillis
}

const getTimestampFromUrl = (href: string) => {
  const url = new URL(href)
  return url.searchParams.get('timestamp') as string
}
