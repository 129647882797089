import React, { useState } from 'react'
import { ampli } from '../../ampli'
import { CheckmarkIcon } from '../shared'

type Props = {
  title: string
  locked?: boolean
  children: React.ReactNode
}

export const Accordion = ({ children, locked, title }: Props) => {
  const [open, setOpen] = useState(false)
  // Generate a randomish number between 0 and 1000 to make accordion header and panel id unique
  const index = Math.floor(Math.random() * 1000)

  return (
    <fieldset
      className={`accordion group ${locked ? 'opacity-70 cursor-not-allowed pointer-events-none' : 'opacity-100'}`}
    >
      <h2>
        <button
          id={`accordion-header-${index}`}
          aria-label={title}
          aria-expanded={open}
          className={open ? 'open' : ''}
          aria-controls={`accordion-panel-${index}`}
          onClick={(evt: React.MouseEvent) => {
            evt.preventDefault()
            ampli.toggleCharityFormSection({ state: open ? 'close' : 'open', section: title })
            setOpen(!open)
          }}
        >
          <span className="flex items-center text-md md:text-xl">
            <div className="mr-4 text-fog">
              <CheckmarkIcon
                width="2rem"
                height="2rem"
                className="[&>*]:fill-dust group-valid:[&>*]:fill-mint-green-dark"
              />
            </div>
            <span className="flex-1 text-left">{title}</span>
          </span>
        </button>
      </h2>

      <div
        id={`accordion-panel-${index}`}
        aria-labelledby={`accordion-header-${index}`}
        className={`space-y-8 ${open ? 'h-min pb-6 px-4' : 'h-0 overflow-hidden'}`}
      >
        {children}
      </div>
    </fieldset>
  )
}
