import { logError } from '@tomra/datadog-browser-logging'
import { intlFormat, parseISO } from 'date-fns'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { Modal } from '../../../components/shared'
import { Diff } from '../../../components/shared/Diff'
import { SavingStatus } from '../../../components/shared/SavingStatus'
import { auth } from '../../../firebase'
import { flattenObject } from '../../../lib/charity'
import { findStateDifference } from '../../../lib/stateHelpers'
import { updateDraft } from '../../../services/firestore.service'

type Props = {
  original: Charity | null
  formValues: Charity
  uiFields: CharityUiFields
}

export const CharityDraft: FunctionComponent<Props> = ({ original, formValues, uiFields }) => {
  const adminId = auth.currentUser!.uid
  const [draftStatus, setDraftStatus] = useState<RequestStatus>('loading')
  const [savedDraft, setSavedDraft] = useState<Draft>()
  const [unsubmittedChanges, setUnsubmittedChanges] = useState({})
  const [showUnsubmittedChanges, setShowUnsubmittedChanges] = useState(false)

  const debouncedSave = useDebouncedCallback((values: Partial<Draft>) => {
    setDraftStatus('loading')

    updateDraft(adminId, values)
      .then(updatedDraft => {
        setSavedDraft(updatedDraft)
        setDraftStatus('success')
      })
      .catch(error => {
        logError(new Error('Unable to save draft'), error)
        setDraftStatus('failed')
      })
  }, 1000)

  useEffect(() => {
    if (original || formValues) {
      const charityDiff = findStateDifference(original, formValues, true)
      debouncedSave({ charity: { ...charityDiff }, ...uiFields, updatedAt: new Date().toISOString() } as Partial<Draft>)
    }
  }, [original, formValues, uiFields, debouncedSave])

  useEffect(() => {
    if (!savedDraft) {
      return
    }

    const changes = findStateDifference(original, savedDraft?.charity)

    setUnsubmittedChanges(changes)
  }, [savedDraft])

  const hasUnsubmittedChanges = original && unsubmittedChanges && Object.keys(unsubmittedChanges).length > 0

  return (
    <>
      <div className="w-full flex flex-col items-center space-y-2">
        <SavingStatus status={draftStatus} />

        <div className="text-sm flex items-center space-x-1">
          {draftStatus === 'loading' ? (
            <span>Verifying changes...</span>
          ) : draftStatus === 'failed' ? (
            <span className="text-center">
              Unable to save changes.
              <br />
              You can still submit the changes.
            </span>
          ) : draftStatus === 'success' && savedDraft ? (
            <>
              <span>Draft saved</span>
              <span>
                {intlFormat(parseISO(savedDraft?.updatedAt), {
                  localeMatcher: 'lookup',
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              </span>
            </>
          ) : null}
        </div>
      </div>

      {hasUnsubmittedChanges && (
        <>
          <div className="flex flex-col items-center space-y-4 !mb-5">
            <div>
              You have{' '}
              <button type="button" className="underline" onClick={() => setShowUnsubmittedChanges(true)}>
                unsubmitted
              </button>{' '}
              changes in your draft.
            </div>
          </div>

          <Modal isOpen={showUnsubmittedChanges}>
            <div className="dialog">
              <div className="dialog-header">
                <h2 className="text-2xl mb-4">Unsubmitted changes</h2>
              </div>
              <div className="dialog-content max-h-[50vh] overflow-y-scroll">
                <Diff original={flattenObject(original)} changes={flattenObject(savedDraft?.charity as Charity)} />
              </div>
              <div className="dialog-footer">
                <button className="btn outlined" onClick={() => setShowUnsubmittedChanges(false)}>
                  Close
                </button>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  )
}
