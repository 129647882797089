import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { AlertIcon, EditIcon, VerifiedIcon } from '../../../../components/shared'
import { Image } from '../../../../components/shared/Image'
import { benefitToReadableString } from '../../../../components/shared/forms/enumsToReadableStrings'

type Props = {
  charity: Charity
  charityPendingChanges: Charity | null | undefined
}

export const CharityCard: FunctionComponent<Props> = ({ charity, charityPendingChanges }) => {
  const hasPendingChanges = Object.keys(charityPendingChanges || {}).length > 0

  if (!charity) {
    return (
      <div className="card p-4">
        <CharityCardSkeleton />
      </div>
    )
  }

  const { id, logoUrl, organization, status } = charity
  const { benefit, displayName, name } = organization

  return (
    <div className="card p-4 grid grid-cols-6 gap-4 items-start">
      <div className="row-start-1 col-start-1 col-span-3 text-sm text-storm dark:text-rock">
        {benefitToReadableString[benefit]}
      </div>
      <div className="row-start-1 col-start-6 col-span-1 flex justify-end">
        <Link to={`/charity/${id}`} className="" aria-label="Edit charity">
          <EditIcon width="1.5rem" height="1.5rem" />
        </Link>
      </div>

      <div className="row-start-2 row-span-3 col-start-1 col-span-2">
        <Image imageUrl={logoUrl as string} altText={`${displayName} logo`} />
      </div>

      <div className="row-start-3 col-start-3 col-span-3 flex flex-col justify-center h-full">
        <div className="text-md font-bold">{displayName}</div>
        <div className="text-storm dark:text-rock">{name}</div>
      </div>

      <div className="row-start-4 col-start-4 col-span-3 flex justify-end">
        {status === 'NEW' ? (
          <span className="chip text-sm !bg-fire-orange-lighter !text-fire-orange-darker">Pending approval</span>
        ) : hasPendingChanges ? (
          <span className="chip text-sm !bg-fire-orange-lighter !text-fire-orange-darker">
            Changes pending approval
          </span>
        ) : status === 'ACTIVE' ? (
          <VerifiedIcon title="Approved" width="1.5rem" height="1.5rem" className="text-teal-dark" />
        ) : (
          <AlertIcon title="Disabled" width="1.5rem" height="1.5rem" className="text-warm-red-dark" />
        )}
      </div>
    </div>
  )
}

const CharityCardSkeleton = () => (
  <>
    <div className="">
      <div className="flex items-start justify-between">
        <div className="skeleton-paragraph w-28" />
        <div className="skeleton-paragraph w-10" />
      </div>

      <div className="flex items-center space-x-4 mt-4">
        <div className="skeleton-circle w-32 h-32" />

        <div className="flex flex-col space-y-2">
          <div className="skeleton-heading w-48" />
          <div className="skeleton-paragraph w-28" />
        </div>
      </div>
    </div>
  </>
)
