import React, { ReactNode } from 'react'
import { THEME } from '../../../lib'
import { AcceptNSWTos } from '../terms-and-conditions/AcceptNSWTos'
import { AcceptTASTos } from '../terms-and-conditions/AcceptTASTos'
import { AcceptVICTos } from '../terms-and-conditions/AcceptVICTos'

type Props = {
  hasAcceptedTos: boolean
  onHasAcceptedTosChange: (hasAcceptedTos: boolean) => void
}

const { stateShort } = THEME

export const TermsAndConditions = ({ hasAcceptedTos, onHasAcceptedTosChange }: Props) => {
  const tosMap: { [key: string]: ReactNode } = {
    NSW: <AcceptNSWTos hasAcceptedTos={hasAcceptedTos} onHasAcceptedTosChange={onHasAcceptedTosChange} />,
    VIC: <AcceptVICTos hasAcceptedTos={hasAcceptedTos} onHasAcceptedTosChange={onHasAcceptedTosChange} />,
    TAS: <AcceptTASTos hasAcceptedTos={hasAcceptedTos} onHasAcceptedTosChange={onHasAcceptedTosChange} />
  }

  return <>{tosMap[stateShort]}</> || null
}
