import { logError } from '@tomra/datadog-browser-logging'
import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'
import { fetchCampaign, fetchCampaigns } from '../services'

type CampaignRequestStatus = {
  campaign: RequestStatus
  pendingChanges: RequestStatus
}

type CampaignContextType = {
  campaignsWithStatistics: CampaignWithStatistics[] | undefined
  campaignRequestStatus: CampaignRequestStatus
  setCharityId: Dispatch<SetStateAction<string>>
  latestCampaign: (CampaignWithStatistics & CampaignWithChanges) | null | undefined
}

const CampaignContext = createContext<CampaignContextType | undefined>(undefined)

export const CampaignContextProvider = ({ children }: { children: ReactNode }) => {
  const [charityId, setCharityId] = useState<string>('')
  const [campaignRequestStatus, setCampaignRequestStatus] = useState<CampaignRequestStatus>({
    campaign: 'loading',
    pendingChanges: 'loading'
  })
  const [campaignsWithStatistics, setCampaignsWithStatistics] = useState<CampaignWithStatistics[]>()
  const [latestCampaign, setLatestCampaign] = useState<
    (CampaignWithStatistics & CampaignWithChanges) | null | undefined
  >()

  useEffect(() => {
    if (!charityId) {
      return
    }

    if (latestCampaign) {
      // Latest campaign already fetched
      return
    }

    fetchCampaigns(charityId)
      .then((campaigns: CampaignWithStatistics[]) => {
        setCampaignsWithStatistics(campaigns)
      })
      .catch(error => {
        logError(new Error('Failed to load campaigns', error))
        setCampaignRequestStatus(prevState => ({ ...prevState, campaign: 'failed' }))
      })
  }, [charityId])

  useEffect(() => {
    if (!charityId || !campaignsWithStatistics) {
      return
    }

    const latest = campaignsWithStatistics?.sort(({ campaign: campaignA }, { campaign: campaignB }) =>
      campaignA?.createdAt < campaignB?.createdAt ? 1 : -1
    )?.[0] as CampaignWithStatistics

    if (latest) {
      fetchCampaign(charityId, latest.campaign.campaignId)
        .then(campaign => {
          setLatestCampaign(campaign as CampaignWithStatistics & CampaignWithChanges)
          setCampaignRequestStatus(prevState => ({ ...prevState, campaign: 'loaded', pendingChanges: 'loaded' }))
        })
        .catch(error => {
          setCampaignRequestStatus(prevState => ({ ...prevState, campaign: 'failed' }))
          logError(new Error('Failed to load latest campaign', error))
          setLatestCampaign(null)
        })
    } else {
      setLatestCampaign(null)
    }
  }, [campaignsWithStatistics])

  return (
    <CampaignContext.Provider
      value={{
        campaignsWithStatistics,
        campaignRequestStatus,
        setCharityId,
        latestCampaign
      }}
    >
      {children}
    </CampaignContext.Provider>
  )
}

export const useCampaign = (charityId: string) => {
  const context = useContext(CampaignContext)

  if (!context) {
    throw new Error('useCampaign must be used within a CampaignContextProvider')
  }

  const { campaignsWithStatistics, campaignRequestStatus, setCharityId, latestCampaign } = context

  useEffect(() => {
    setCharityId(charityId)
  }, [charityId])

  return {
    campaignsWithStatistics,
    campaignRequestStatus,
    latestCampaign
  }
}
