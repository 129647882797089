import { logError } from '@tomra/datadog-browser-logging'
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth } from '../firebase'
import { fetchExistingCharity, fetchPendingChangesForCharity } from '../services'

type CharityRequestStatus = {
  charity: RequestStatus
  pendingChanges: RequestStatus
}

type CharityContextType = {
  charity: Charity | null | undefined
  charityPendingChanges: Charity | null | undefined
  charityRequestStatus: CharityRequestStatus
}

const CharityContext = createContext<CharityContextType | undefined>(undefined)

export const CharityContextProvider = ({ children }: { children: ReactNode }) => {
  const [charityRequestStatus, setCharityRequestStatus] = useState<CharityRequestStatus>({
    charity: 'loading',
    pendingChanges: 'loading'
  })
  const [charity, setCharity] = useState<Charity | null>()
  const [charityPendingChanges, setCharityPendingChanges] = useState<Charity | null>()

  useEffect(() => {
    fetchExistingCharity()
      .then(ch => {
        setCharity(!ch ? null : (ch as Charity))
        setCharityRequestStatus(prevState => ({ ...prevState, charity: 'loaded' }))
      })
      .catch(error => {
        if (error.status === 401) {
          return auth.signOut()
        }

        logError(new Error('Failed to load existing charities'), error)
        setCharityRequestStatus(prevState => ({ ...prevState, charity: 'failed' }))
      })
  }, [])

  useEffect(() => {
    if (!charity) {
      return
    }

    fetchPendingChangesForCharity((charity as Charity).id)
      .then(pendingChanges => {
        setCharityPendingChanges(pendingChanges)
        setCharityRequestStatus(prevState => ({ ...prevState, pendingChanges: 'loaded' }))
      })
      .catch(error => {
        logError(new Error('Failed to load pending charity changes'), error)
        setCharityRequestStatus(prevState => ({ ...prevState, pendingChanges: 'failed' }))
      })
  }, [charity])

  return (
    <CharityContext.Provider
      value={{
        charity,
        charityRequestStatus,
        charityPendingChanges
      }}
    >
      {children}
    </CharityContext.Provider>
  )
}

export const useCharity = () => {
  const navigate = useNavigate()
  const context = useContext(CharityContext)

  if (!context) {
    throw new Error('useCharity must be used within a CharityContextProvider')
  }

  const { charity, charityRequestStatus, charityPendingChanges } = context

  useEffect(() => {
    if (charity === null && window.location.pathname !== '/profile') {
      navigate('/charity')
    }
  }, [charity])

  return {
    charity,
    charityRequestStatus,
    charityPendingChanges
  }
}
