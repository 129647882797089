import { addDays, addWeeks, eachDayOfInterval } from 'date-fns'
import { ValidationError, date, number, object, string } from 'yup'

export const MIN_CAMPAIGN_DAYS = 90
export const MAX_CAMPAIGN_DAYS = 365
export const SIX_MONTHS_IN_DAYS = 180
export const MIN_CAMPAIGN_START_DATE = addWeeks(new Date(), 2)

export const currencyFormatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  minimumFractionDigits: 0
})

export const calculateDaysRunning = (startTime: Date, endTime: Date) => {
  return eachDayOfInterval({ start: startTime, end: endTime }).length
}

export const calculateMaxDaysLeftToExtend = (startTime: Date, endTime: Date) => {
  const interval = { start: startTime, end: endTime }
  const days = eachDayOfInterval(interval)
  return MAX_CAMPAIGN_DAYS - days.length
}

const financialTargetErrorMessage = `Must be between ${currencyFormatter.format(100)} and ${currencyFormatter.format(
  1000000
)}`

export const ValidCampaign = object({
  campaignId: string(),
  charityId: string(),
  name: string().required(),
  description: string().required(),
  startTime: date()
    .required()
    .when('createdAt', ([createdAt], schema) => {
      if (createdAt) {
        return schema
      }

      return schema.min(addDays(new Date(), 13), `The start date must be at least 14 days from today.`)
    }),
  endTime: date()
    .required()
    .when('startTime', ([startTime], schema) => {
      const minDate = addDays(startTime, MIN_CAMPAIGN_DAYS)
      const maxDate = addDays(startTime, MAX_CAMPAIGN_DAYS + 1)

      return schema
        .min(minDate, `The duration must be between ${MIN_CAMPAIGN_DAYS} and ${MAX_CAMPAIGN_DAYS} days.`)
        .max(maxDate, `The duration must be between ${MIN_CAMPAIGN_DAYS} and ${MAX_CAMPAIGN_DAYS} days.`)
    }),
  financialTarget: number()
    .typeError(financialTargetErrorMessage)
    .min(100, financialTargetErrorMessage)
    .max(1000000, financialTargetErrorMessage),
  engagementPlan: string(),
  createdAt: string(),
  campaignCode: string(),
  status: string(),
  logoUrl: string().nullable(),
  instagramHandle: string().nullable(),
  youtubeHandle: string().nullable(),
  tiktokHandle: string().nullable(),
  facebookHandle: string().nullable(),
  snapchatHandle: string().nullable(),
  linkedinHandle: string().nullable()
})

const campaignPathMap: { [key: string]: string } = {
  name: 'Name',
  description: 'Description',
  startTime: 'Start date',
  endTime: 'End date',
  financialTarget: 'Financial target',
  engagementPlan: 'Awareness and Engagement plan'
}

export function validateCampaign(campaign: Campaign) {
  try {
    ValidCampaign.validateSync(campaign, { abortEarly: false })
    return []
  } catch (error) {
    if (!error) {
      return []
    }

    return (error as ValidationError).inner.map(error => {
      const { path, type, message } = error

      const mappedPath = campaignPathMap[path as string] || path

      return {
        path,
        type,
        message: message.replace(path as string, mappedPath as string)
      }
    })
  }
}
