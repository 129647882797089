import React, { useState } from 'react'
import Cropper from 'react-easy-crop'
import { THEME } from '../../lib'
import { ZoomInIcon, ZoomOutIcon } from '../shared'
import { FileUpload } from './FileUpload'
import { Modal } from './Modal'

const browserNotSupported = !!window.navigator.userAgent.toLowerCase().match(/(edge|trident)/g)
const { displayName } = THEME

type Props = {
  uploadedFile: Attachment | undefined
  children: React.ReactNode
  onChange: (file: File | Blob, name: string) => void
  requestStatus: RequestStatus
  id: string
  required?: boolean
}

export const ImageUpload = ({ uploadedFile, children, onChange, requestStatus, id, required }: Props) => {
  const [processingImage, setProcessingImage] = useState<HTMLImageElement | null>(null)
  const [processingImageFile, setProcessingImageFile] = useState<File | null>(null)
  const [croppedImageFile, setCroppedImageFile] = useState<File | Blob | null>(null)
  const [showCropDialog, setShowCropDialog] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [cropCoordinates, setCropCoordinates] = useState({ x: 0, y: 0 })
  const [cropZoom, setCropZoom] = useState(0.5)
  const [backgroundColor, setBackgroundColor] = useState('white')

  const closeCropDialog = () => {
    setErrorMsg('')
    setShowCropDialog(false)
  }

  const onFileSelected = (uploadedFile: File) => {
    const fr = new FileReader()

    fr.onload = () => {
      const img = new Image()

      img.onload = () => {
        const fileSizeMb = (img.src.length * 0.75) / 1000000

        if (img.width < 500 || img.height < 500) {
          setErrorMsg(
            `Logo must have a width and height of at least 500px. Your uploaded logo is ${img.width}x${img.height}.`
          )
        } else if (fileSizeMb > 2) {
          setErrorMsg('File size cannot exceed 2 megabytes.')
        } else if (browserNotSupported && img.width !== img.height) {
          setErrorMsg('Height and width must be identical (i.e a square)')
        } else if (browserNotSupported) {
          onChange(uploadedFile, uploadedFile.type)
        } else {
          setErrorMsg('')
          setProcessingImage(img)
          setProcessingImageFile(uploadedFile)
          setShowCropDialog(true)
        }
      }
      // @ts-ignore
      img.src = fr.result
    }

    fr.readAsDataURL(uploadedFile)
  }

  const onCropComplete = (_croppedArea: any, croppedAreaPixels: any) => {
    const croppedWidth = croppedAreaPixels.width
    const croppedHeight = croppedAreaPixels.height

    const canvas = document.createElement('canvas')
    canvas.width = croppedWidth
    canvas.height = croppedHeight
    const context = canvas?.getContext('2d')

    if (context) {
      context.fillStyle = backgroundColor
      context.fillRect(0, 0, croppedWidth, croppedHeight)
    }

    const sourceX = croppedAreaPixels.x
    const sourceY = croppedAreaPixels.y
    const sourceWidth = croppedWidth
    const sourceHeight = croppedHeight
    const destWidth = croppedWidth
    const destHeight = croppedHeight
    const destX = 0
    const destY = 0

    context?.drawImage(
      processingImage!,
      sourceX,
      sourceY,
      sourceWidth,
      sourceHeight,
      destX,
      destY,
      destWidth,
      destHeight
    )

    canvas.toBlob(setCroppedImageFile, processingImageFile?.type)
  }

  const onDone = () => {
    closeCropDialog()
    onChange(croppedImageFile!, processingImageFile!.name)
  }

  return (
    <FileUpload
      id={id}
      description="Logo"
      attachmentErrors={[]}
      uploadedFile={uploadedFile}
      onChange={onFileSelected}
      requestStatus={requestStatus}
      errorMsg={errorMsg}
      acceptedFileTypes="image/png, image/jpeg, image/jpg"
      required={required}
    >
      {children}

      {browserNotSupported && (
        <div className="alert warning my-2">
          Your browser doesn&apos;t support image cropping, so uploaded logo must be a perfect square.
        </div>
      )}

      <Modal isOpen={showCropDialog}>
        <div className="h-[80vh]">
          <Cropper
            image={processingImage?.src}
            crop={cropCoordinates}
            aspect={1}
            onCropChange={setCropCoordinates}
            onCropComplete={onCropComplete}
            onZoomChange={setCropZoom}
            minZoom={0.2}
            cropShape="round"
            zoom={cropZoom}
            restrictPosition={false}
            style={{ containerStyle: { backgroundColor } }}
            showGrid={false}
          />
        </div>

        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 z-10 card !w-[50vw] p-4 grid grid-cols-4 gap-x-4 items-center">
          <div className="col-span-1 col-start-1">
            <div
              className="tooltip"
              data-tip={`Place your logo within the circle by moving it with your cursor and/or zooming in and out. This will
              reflect how your logo will look like in the ${displayName} app.`}
            >
              Need help?
            </div>
          </div>

          <div className="col-start-2 col-span-1 flex items-center justify-evenly">
            <div className="mr-2">Background</div>
            <button
              aria-label="Set image background to black"
              className="btn-icon !p-2"
              onClick={() => {
                setBackgroundColor('black')
                // in order to trigger onCropComplete which triggers canvas background to change
                setCropZoom(cropZoom + 0.0001)
              }}
            >
              <div
                className={`w-8 h-8 rounded-full bg-black border ${
                  backgroundColor === 'black' ? 'outline outline-warm-red/20' : ''
                }`}
              />
            </button>
            <button
              aria-label="Set image background to white"
              className="btn-icon !p-2"
              onClick={() => {
                setBackgroundColor('white')
                // in order to trigger onCropComplete which triggers canvas background to change
                setCropZoom(cropZoom + 0.0001)
              }}
            >
              <div
                className={`w-8 h-8 rounded-full bg-white border ${
                  backgroundColor === 'white' ? 'outline outline-warm-red/20' : ''
                }`}
              />
            </button>
          </div>

          <div className="col-start-3 col-span-1 flex items-center justify-evenly">
            <button
              aria-label="Zoom out"
              className="btn-icon col-start-6 col-span-1"
              onClick={() => cropZoom >= 0.2 && setCropZoom(cropZoom - 0.1)}
            >
              <ZoomOutIcon width="2rem" height="2rem" />
            </button>
            <button
              aria-label="Zoom in"
              className="btn-icon col-start-7 col-span-1"
              onClick={() => setCropZoom(cropZoom + 0.1)}
            >
              <ZoomInIcon width="2rem" height="2rem" />
            </button>
          </div>

          <div className="col-start-4 col-span-1 flex items-center justify-evenly">
            <button className="btn btn-sm" onClick={closeCropDialog}>
              Cancel
            </button>

            <button className="btn btn-sm primary" disabled={croppedImageFile === null} onClick={onDone}>
              Done
            </button>
          </div>
        </div>
      </Modal>
    </FileUpload>
  )
}
