import { differenceInDays, formatDistanceStrict } from 'date-fns'
import React, { FunctionComponent } from 'react'

type Props = {
  endTime: string
  status: Campaign['status']
}

export const CampaignEnd: FunctionComponent<Props> = ({ endTime, status }) => {
  const today = new Date()

  const campaignEndDate = new Date(endTime)
  const daysUntilCampaignEnds = differenceInDays(campaignEndDate, today)
  const campaignHasEnded = daysUntilCampaignEnds < 0

  if (status === 'NEW' || status === 'STOPPED') {
    return (
      <>
        <div className="text-sm text-storm dark:text-rock">Scheduled end</div>
        <div className="text-xl">
          {formatDistanceStrict(campaignEndDate, new Date(), { addSuffix: true, unit: 'day' })}
        </div>
        <div className="text-sm">{campaignEndDate.toLocaleDateString()}</div>
      </>
    )
  }

  if (!campaignHasEnded) {
    return (
      <>
        <div className="text-sm text-storm dark:text-rock">Ends</div>
        <div className="text-xl">
          {formatDistanceStrict(campaignEndDate, new Date(), { addSuffix: true, unit: 'day' })}
        </div>
        <div className="text-sm">{campaignEndDate.toLocaleDateString()}</div>
      </>
    )
  }

  return (
    <>
      <div className="text-sm text-storm dark:text-rock">Ended</div>
      <div className="text-xl">{campaignEndDate.toLocaleDateString()}</div>
    </>
  )
}
