import React from 'react'
import { Accordion, Label } from '../'

type Props = {
  primaryContact: PrimaryContact
  onPrimaryContactFieldsChange: (primaryContact: Partial<PrimaryContact>) => void
  secondaryContact: SecondaryContact
  onSecondaryContactFieldsChange: (secondaryContact: Partial<SecondaryContact>) => void
}

export const Contact = ({
  primaryContact,
  onPrimaryContactFieldsChange,
  secondaryContact,
  onSecondaryContactFieldsChange
}: Props) => {
  const secondaryContactStarted = !!(
    secondaryContact?.firstName ||
    secondaryContact?.lastName ||
    secondaryContact?.phone ||
    secondaryContact?.position ||
    secondaryContact?.email
  )

  return (
    <Accordion title="Contact information">
      <div className="alert info text-sm w-1/2">The donation reports are sent to the primary email address</div>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 lg:col-span-1 flex flex-col space-y-6">
          <h3 className="ml-2 text-lg">Primary contact</h3>

          <div className="input-field">
            <Label htmlFor="primaryFirstName">First name</Label>

            <input
              type="text"
              id="primaryFirstName"
              className="input-text inverted"
              value={primaryContact.firstName}
              onChange={evt => onPrimaryContactFieldsChange({ firstName: evt.target.value })}
              required
            />
          </div>

          <div className="input-field">
            <Label htmlFor="primaryLastName">Last name</Label>

            <input
              type="text"
              id="primaryLastName"
              className="input-text inverted"
              value={primaryContact.lastName}
              onChange={evt => onPrimaryContactFieldsChange({ lastName: evt.target.value })}
              required
            />
          </div>

          <div className="input-field">
            <Label htmlFor="primaryPosition">Position</Label>

            <input
              type="text"
              id="primaryPosition"
              className="input-text inverted"
              value={primaryContact.position}
              onChange={evt => onPrimaryContactFieldsChange({ position: evt.target.value })}
              required
            />
          </div>

          <div className="input-field">
            <Label htmlFor="primaryEmail">Email</Label>

            <input
              id="primaryEmail"
              type="email"
              className="input-text inverted"
              value={primaryContact.email}
              onChange={evt => onPrimaryContactFieldsChange({ email: evt.target.value })}
              pattern="^.+@.+\..+$"
              title="Must be a valid email address."
              required
            />
          </div>

          <div className="input-field">
            <Label htmlFor="primaryPhone">Phone number</Label>

            <input
              type="tel"
              id="primaryPhone"
              className="input-text inverted"
              value={primaryContact.phone}
              onChange={evt => onPrimaryContactFieldsChange({ phone: evt.target.value })}
              pattern="^[0-9]{10,}$"
              title="10 digit phone number."
            />
          </div>
        </div>

        <div className="col-span-2 lg:col-span-1 flex flex-col space-y-6">
          <h3 className="ml-2 text-lg">Secondary contact</h3>

          <div className="input-field">
            <Label htmlFor="secondaryFirstName">First name</Label>

            <input
              type="text"
              id="secondaryFirstName"
              className="input-text inverted"
              value={secondaryContact?.firstName || ''}
              onChange={evt => onSecondaryContactFieldsChange({ firstName: evt.target.value })}
              required={secondaryContactStarted}
            />
          </div>

          <div className="input-field">
            <Label htmlFor="secondaryLastName">Last name</Label>

            <input
              type="text"
              id="secondaryLastName"
              className="input-text inverted"
              value={secondaryContact?.lastName || ''}
              onChange={evt => onSecondaryContactFieldsChange({ lastName: evt.target.value })}
              required={secondaryContactStarted}
            />
          </div>

          <div className="input-field">
            <Label htmlFor="secondaryPosition">Position</Label>

            <input
              type="text"
              id="secondaryPosition"
              className="input-text inverted"
              value={secondaryContact?.position || ''}
              onChange={evt => onSecondaryContactFieldsChange({ position: evt.target.value })}
              required={secondaryContactStarted}
            />
          </div>

          <div className="input-field">
            <Label htmlFor="secondaryEmail">Email</Label>

            <input
              id="secondaryEmail"
              type="email"
              className="input-text inverted"
              value={secondaryContact?.email || ''}
              onChange={evt => onSecondaryContactFieldsChange({ email: evt.target.value })}
              pattern="^.+@.+\..+$"
              title="Must be a valid email address."
              required={secondaryContactStarted}
            />
          </div>

          <div className="input-field">
            <Label htmlFor="secondaryPhone">Phone number</Label>

            <input
              type="tel"
              id="secondaryPhone"
              className="input-text inverted"
              value={secondaryContact?.phone || ''}
              onChange={evt => onSecondaryContactFieldsChange({ phone: evt.target.value })}
              pattern="^[0-9]{10,}$"
              title="10 digit phone number."
            />
          </div>
        </div>
      </div>
    </Accordion>
  )
}
