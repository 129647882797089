import anime from 'animejs/lib/anime.es.js'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { AnimatedStars } from './AnimatedStars'

type Props = {
  current: number
  total: number
}

const animatedObject = {
  donated: 0
}

export const ProgressBar: FunctionComponent<Props> = ({ current, total }) => {
  const [showStars, setShowStars] = useState(false)
  const progressBarRef = useRef<HTMLDivElement>(null)

  const completionPercentage = Math.floor((current / total) * 100)
  // We don't want the animation to go bananas if percentage is above 100 so max should be 100
  const progressBarPercentage = completionPercentage <= 100 ? completionPercentage : 100

  useEffect(() => {
    if (!progressBarRef.current) {
      return
    }

    anime({
      targets: animatedObject,
      donated: progressBarPercentage,
      round: 1,
      easing: 'spring(1, 80, 13, 0)',
      update: () => {
        if (progressBarRef.current) {
          ;(progressBarRef.current as HTMLDivElement).style.width = `${animatedObject.donated}%`
        }

        if (animatedObject.donated === 100) {
          setShowStars(true)
        }
      }
    })
  }, [progressBarRef])

  return (
    <div className="relative w-full">
      <div className="h-4 rounded-full bg-fog mt-4 mb-2">
        <div ref={progressBarRef} className="rounded-full bg-light-pink w-[0%] h-full transition" />
      </div>

      {showStars && <AnimatedStars />}
    </div>
  )
}
