import React, { useState, useEffect, FunctionComponent, useMemo, ReactNode } from 'react'
import { AlertIcon, Banking, Contact, Description, Details, Eligibility, Location } from '../../../components/shared'
import { charityInit } from '../../../initialData'
import { THEME } from '../../../lib'
import { parseAttachmentErrors, validateCharityForm } from '../../../lib/charity'
import { findStateDifference } from '../../../lib/stateHelpers'
import { useCharityFormFields } from '../../../reducers/charityFormFieldsReducer'
import { useUiFields } from '../../../reducers/uiFieldsReducer'
import { CharityDraft } from './CharityDraft'

const { displayName, supportEmail } = THEME

type Props = {
  savedDraft: Draft
  original: Charity | null
  onSubmit: (charity: Charity) => void
  submitRequestStatus: RequestStatus
  submitError: string
  formHeading: ReactNode
  acceptTerms?: ReactNode
}

export const CharityForm: FunctionComponent<Props> = ({
  savedDraft,
  original,
  onSubmit,
  submitRequestStatus,
  submitError,
  formHeading,
  acceptTerms
}) => {
  const [formValidationErrors, setFormValidationErrors] = useState<{ [key: string]: FormValidationError[] }>({})
  const [showFormValidationErrors, setShowFormValidationErrors] = useState(false)

  const {
    formValues,
    updateLogoUrl,
    updateOrganization,
    updateBankingData,
    updatePrimaryContact,
    updateSecondaryContact
  } = useCharityFormFields(original, savedDraft?.charity)

  const { uiFieldsState, updateUiField, updateAttachmentErrors } = useUiFields(original, savedDraft)

  const diff = useMemo(() => findStateDifference(original, formValues), [formValues])
  const charityHasChanges = Object.keys(diff).length > 0

  useEffect(() => {
    const allChanges = {
      hasAcceptedTos: savedDraft.hasAcceptedTos,
      charity: formValues,
      uiFields: uiFieldsState
    } as CharityFormState
    const errors = validateCharityForm(allChanges)
    setFormValidationErrors(errors as { [key: string]: FormValidationError[] })

    if (Object.keys(errors).length === 0) {
      setShowFormValidationErrors(false)
    }
  }, [savedDraft, formValues, uiFieldsState])

  useEffect(() => {
    updateAttachmentErrors(parseAttachmentErrors(submitError))
  }, [submitError])

  return (
    <>
      <form
        className="group"
        onSubmit={e => {
          e.preventDefault()

          const hasErrors = Object.keys(formValidationErrors).length > 0
          setShowFormValidationErrors(hasErrors)

          if (charityHasChanges && !hasErrors) {
            onSubmit(formValues)
          }
        }}
        noValidate
      >
        <div className="flex flex-col space-y-md md:max-w-screen-2xl md:mx-auto md:my-md md:grid md:grid-cols-12 md:gap-x-6">
          <div className="md:col-span-8 space-y-6">
            <div className="card px-6 py-6 flex flex-col space-y-6">
              <div className="mb-4">{formHeading}</div>
            </div>

            {acceptTerms}

            <Eligibility
              organization={formValues.organization}
              onOrganizationFieldsChange={updateOrganization}
              uiFields={uiFieldsState}
              onUiFieldsChange={updateUiField}
            />

            <Details
              charityId={formValues.id}
              uiFields={uiFieldsState}
              onUiFieldsChange={updateUiField}
              organization={formValues.organization}
              onOrganizationFieldsChange={updateOrganization}
            />

            <Location organization={formValues.organization} onOrganizationFieldsChange={updateOrganization} />

            <Description
              charityId={formValues.id}
              uiFields={uiFieldsState}
              onUiFieldsChange={updateUiField}
              organization={formValues.organization}
              onOrganizationFieldsChange={updateOrganization}
              logoUrl={uiFieldsState.OrganizationLogo?.filename || (charityInit.logoUrl as string)}
              setLogoUrl={logoUrl => {
                updateUiField({ OrganizationLogo: { filename: logoUrl, uploadedAt: new Date().toISOString() } })
                updateLogoUrl(logoUrl)
              }}
            />

            <Banking
              charityId={formValues.id}
              uiFields={uiFieldsState}
              onUiFieldsChange={updateUiField}
              originalBankingData={original?.bankingData as BankingData}
              bankingData={formValues.bankingData}
              onBankingDataFieldsChange={updateBankingData}
              validationErrors={formValidationErrors['Finance'] || []}
            />

            <Contact
              primaryContact={formValues.primaryContact}
              onPrimaryContactFieldsChange={updatePrimaryContact}
              secondaryContact={formValues.secondaryContact}
              onSecondaryContactFieldsChange={updateSecondaryContact}
            />
          </div>

          <div className="md:col-span-4">
            <aside className="top-4 sticky flex flex-col space-y-6 items-center card p-4">
              <CharityDraft original={original} formValues={formValues} uiFields={uiFieldsState} />

              <div className="flex items-end space-x-2 w-full md:w-auto">
                <button
                  type="submit"
                  className="btn primary w-full md:w-auto aria-disabled:bg-fog aria-disabled:shadow-none group-invalid:cursor-not-allowed h-3"
                  aria-disabled={submitRequestStatus === 'loading' || Object.keys(formValidationErrors)?.length > 0}
                >
                  {submitRequestStatus === 'loading' ? (
                    <div className="loading-infinity w-8 bg-white" />
                  ) : (
                    'Submit for review'
                  )}
                </button>
              </div>

              {submitRequestStatus === 'failed' && (
                <div className="alert danger">
                  <p className="font-bold">
                    Something went wrong. Please try submitting again shortly. If the issue persists, please contact us
                    at
                    <a href={`mailto:${supportEmail}`} className="no-underline border-current border-b-2 ml-xs">
                      {` ${supportEmail}`}
                    </a>
                  </p>
                </div>
              )}

              {submitRequestStatus === 'preConditionFailed' && (
                <>
                  <div className="flex items-start space-x-4">
                    <div>
                      <AlertIcon width="2em" />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div>
                        <p className="text-warm-red-dark font-bold">
                          There&apos;s an issue with {uiFieldsState.attachmentErrors.length} of the uploaded files.
                          Please re-upload:
                        </p>
                      </div>
                      <ul className="list-disc">
                        {uiFieldsState.attachmentErrors.map(attachment => (
                          <li key={`e-${attachment.filename}`}>
                            {attachment.displayName} under &quot;{attachment.section}&quot;
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </>
              )}

              {showFormValidationErrors && (
                <div>
                  <h2 className="text-warm-red-dark font-bold mb-4">
                    Please correct the following before submitting the form:
                  </h2>
                  <ul className="flex flex-col space-y-6">
                    {Object.keys(formValidationErrors).map((key, i) => (
                      <li key={`se-${i}`}>
                        <span className="font-bold">{key}</span>
                        <ul className="flex flex-col space-y-2 pl-4 list-disc">
                          {formValidationErrors[key].map((error, i) => (
                            <li key={`em-${i}`}>{error.message}</li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </aside>
          </div>
        </div>
      </form>

      <div className="flex items-center justify-center mx-auto max-w-screen-sm mt-8">
        <img src={`themes/${THEME.brandingLogos}`} alt={`${displayName} logos`} className="max-h-16" />
      </div>
    </>
  )
}
