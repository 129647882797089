import React from 'react'
import { Accordion, InfoTooltip, Label } from '../'
import { geoFootprintToReadableString } from './enumsToReadableStrings'

type Props = {
  organization: Organization
  onOrganizationFieldsChange: (organization: Partial<Organization>) => void
}

export const Location = ({ organization, onOrganizationFieldsChange }: Props) => (
  <Accordion title="Location and operational footprint">
    <div className="input-field">
      <Label htmlFor="streetAddress">Street address</Label>

      <input
        type="text"
        id="streetAddress"
        className="input-text inverted"
        maxLength={35}
        value={organization.streetAddress}
        onChange={evt => onOrganizationFieldsChange({ streetAddress: evt.target.value })}
        required
      />
      <p className="ml-4 text-sm">{organization.streetAddress.length}/35 characters</p>
    </div>

    <div className="input-field">
      <Label htmlFor="suburb">Suburb</Label>

      <input
        type="text"
        id="suburb"
        className="input-text inverted"
        value={organization.suburb}
        onChange={evt => onOrganizationFieldsChange({ suburb: evt.target.value })}
      />
    </div>

    <div className="input-field">
      <Label htmlFor="postCode">Postcode</Label>

      <input
        type="text"
        id="postCode"
        className="input-text inverted"
        value={organization.postCode}
        onChange={evt => onOrganizationFieldsChange({ postCode: evt.target.value })}
        required
      />
    </div>

    <fieldset>
      <Label>Describe your operational footprint</Label>
      <ul className="radio-options">
        <li className="flex space-x-4 items-center">
          <label htmlFor="geoFootprint1" className="inverted">
            <input
              type="radio"
              id="geoFootprint1"
              value="LOCAL"
              name="geoFootprint"
              checked={organization.geoFootprint === 'LOCAL'}
              onChange={(evt: any) => onOrganizationFieldsChange({ geoFootprint: evt.target.value })}
              required
            />
            {geoFootprintToReadableString['LOCAL']}
          </label>
          <InfoTooltip content="Local must be a school, sports club, local not-for-profit organisation or a community group with a strong local presence that can demonstrate a broad community benefit, within the local area, in which a reverse vending machine(s) is located." />
        </li>

        <li>
          <label htmlFor="geoFootprint2" className="inverted">
            <input
              type="radio"
              id="geoFootprint2"
              value="STATE"
              name="geoFootprint"
              checked={organization.geoFootprint === 'STATE'}
              onChange={(evt: any) => onOrganizationFieldsChange({ geoFootprint: evt.target.value })}
              required
            />
            {geoFootprintToReadableString['STATE']}
          </label>
        </li>

        <li>
          <label htmlFor="geoFootprint3" className="inverted">
            <input
              type="radio"
              id="geoFootprint3"
              value="NATIONAL"
              name="geoFootprint"
              checked={organization.geoFootprint === 'NATIONAL'}
              onChange={(evt: any) => onOrganizationFieldsChange({ geoFootprint: evt.target.value })}
              required
            />
            {geoFootprintToReadableString['NATIONAL']}
          </label>
        </li>
      </ul>
    </fieldset>
  </Accordion>
)
