import React from 'react'
import { ErrorCard } from '../../components/shared/ErrorCard'
import { useCampaign } from '../../context/CampaignContextProvider'
import { useCharity } from '../../context/CharityContextProvider'
import { CampaignStatistics } from './common/CampaignStatistics'
import { CharityState } from './common/CharityState'
import { DisabledCharity } from './common/DisabledCharity'
import { CampaignCard } from './common/campaign/CampaignCard'
import { CharityCard } from './common/charity/CharityCard'

export const DashboardPage = () => {
  const { charity, charityRequestStatus, charityPendingChanges } = useCharity()
  const { campaignsWithStatistics, campaignRequestStatus, latestCampaign } = useCampaign(charity?.id as string)

  if (charityRequestStatus.charity === 'failed' || campaignRequestStatus.campaign === 'failed') {
    return <ErrorCard />
  }

  return (
    <>
      <h1 className="sr-only">Charity Dashboard</h1>

      {charity?.status === 'DISABLED' ? (
        <DisabledCharity />
      ) : (
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-8 lg:row-span-1">
            <CampaignCard
              charity={charity as Charity}
              campaignWithStatistics={latestCampaign as CampaignWithStatistics}
            />
          </div>

          <div className="lg:col-start-9 lg:col-span-4 lg:row-span-2">
            <CharityCard charity={charity as Charity} charityPendingChanges={charityPendingChanges} />
          </div>

          {campaignsWithStatistics && campaignsWithStatistics.length > 1 ? (
            <CampaignStatistics campaignsWithStatistics={campaignsWithStatistics} />
          ) : (
            charity && (
              <CharityState
                charityId={charity.id}
                charityStatus={charity.status}
                latestCampaignStatus={latestCampaign?.campaign?.status}
              />
            )
          )}
        </div>
      )}
    </>
  )
}
