import { addDays, subYears } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'
import { THEME } from './lib'
import { MAX_CAMPAIGN_DAYS, MIN_CAMPAIGN_START_DATE } from './lib/campaign'

const organizationInit: Organization = {
  name: '',
  websiteUrl: '',
  organizationAbn: '',
  operatingState: THEME.stateShort,
  establishedDate: addDays(subYears(new Date(), 2), 1).toISOString(),
  legalStructure: '',
  legalStructureDetails: '',
  geoFootprint: '',
  incorporationNumber: '',
  displayName: '',
  localGroupName: '',
  streetAddress: '',
  postCode: '',
  suburb: '',
  benefit: '',
  description: '',
  registeredState: '',
  externalId: null
}

const bankingDataInit: BankingData = {
  accountName: '',
  accountBsb: '',
  accountNumber: '',
  dgrStatus: ''
}

const primaryContactInit: PrimaryContact = {
  firstName: '',
  lastName: '',
  position: '',
  email: '',
  phone: ''
}

const secondaryContactInit: SecondaryContact = {
  firstName: '',
  lastName: '',
  position: '',
  email: '',
  phone: ''
}

const charityInit: Charity = {
  id: uuidv4(),
  organization: organizationInit,
  bankingData: bankingDataInit,
  primaryContact: primaryContactInit,
  secondaryContact: secondaryContactInit,
  logoUrl: null
}

const campaignInit: Campaign = {
  campaignId: '',
  name: '',
  description: '',
  startTime: MIN_CAMPAIGN_START_DATE,
  endTime: addDays(MIN_CAMPAIGN_START_DATE, MAX_CAMPAIGN_DAYS),
  financialTarget: 0,
  engagementPlan: '',
  createdAt: '',
  charityId: '',
  campaignCode: '',
  status: 'NEW',
  logoUrl: '',
  instagramHandle: '',
  youtubeHandle: '',
  tiktokHandle: '',
  facebookHandle: '',
  snapchatHandle: '',
  linkedinHandle: ''
}

export { charityInit, campaignInit }
