import { logError } from '@tomra/datadog-browser-logging'
import React, { useEffect, useState } from 'react'
import { Accordion, FileUpload, Label } from '../'
import { THEME } from '../../../lib'
import { checkIfBankingDataHasChanged } from '../../../lib/charity'
import { uploadFile } from '../../../services'

type Props = {
  charityId: string
  uiFields: CharityUiFields
  onUiFieldsChange: (uiFields: Partial<CharityUiFields>) => void
  bankingData: BankingData
  onBankingDataFieldsChange: (bankingData: Partial<BankingData>) => void
  originalBankingData: BankingData | null
  validationErrors: FormValidationError[]
}

const { displayName } = THEME

export const Banking = ({
  charityId,
  uiFields,
  onUiFieldsChange,
  bankingData,
  onBankingDataFieldsChange,
  originalBankingData,
  validationErrors
}: Props) => {
  const [bankDetailsRequestStatus, setBankDetailsRequestStatus] = useState<RequestStatus>('initial')
  const [bankDetailsHasChanged, setBankDetailsHasChanged] = useState(() =>
    checkIfBankingDataHasChanged(originalBankingData, bankingData)
  )

  const hasRestrictedDgr =
    bankingData.dgrStatus === 'RESTRICTED' ||
    bankingData.dgrStatus === 'RESTRICTED_DGR_ACTIVITIES_YES' ||
    bankingData.dgrStatus === 'RESTRICTED_DGR_ACTIVITIES_NO'

  const onBankDetailsUpload = async (proofOfBankDetailsFile: File) => {
    setBankDetailsRequestStatus('loading')

    try {
      await uploadFile(proofOfBankDetailsFile, charityId, 'ProofOfBankDetails')
      setBankDetailsRequestStatus('initial')
      onUiFieldsChange({
        ProofOfBankDetails: { filename: proofOfBankDetailsFile.name, uploadedAt: new Date().toISOString() }
      })
    } catch (error: any) {
      setBankDetailsRequestStatus('failed')
      logError(new Error('Failed to upload ProofOfBankDetails file'), error)
    }
  }

  useEffect(() => {
    const detailsHaveChanged = checkIfBankingDataHasChanged(originalBankingData, bankingData)
    setBankDetailsHasChanged(detailsHaveChanged)

    if (detailsHaveChanged) {
      onUiFieldsChange({ ProofOfBankDetails: undefined })
    }
  }, [originalBankingData, bankingData])

  return (
    <Accordion title="Finance" locked={bankDetailsRequestStatus === 'loading'}>
      <div className="alert info">
        <p>We do not share any of your finance details with a third party.</p>
        <p>Your bank details are secured and only used to make payment of the donations.</p>
      </div>

      <div className="input-field">
        <Label htmlFor="accountName">Account name</Label>

        <input
          type="text"
          id="accountName"
          className="input-text inverted"
          value={bankingData.accountName}
          onChange={evt => onBankingDataFieldsChange({ accountName: evt.target.value })}
          required
        />
      </div>

      <div className="input-field">
        <Label htmlFor="accountBsb">BSB</Label>

        <input
          type="text"
          id="accountBsb"
          value={bankingData.accountBsb}
          className="input-text inverted"
          onChange={evt => onBankingDataFieldsChange({ accountBsb: evt.target.value.replace(/\s/g, '') })}
          pattern="^\d{6}$"
          maxLength={6}
          title="6 digits"
          required
        />
        {validationErrors
          .filter(error => {
            const isBsbError = error.path.includes('accountBsb')
            return isBsbError && error.type === 'matches' && bankingData.accountBsb.length > 0
          })
          .map((bsbError, i) => {
            return (
              <div key={`bsbError-${i}`} className="absolute -bottom-6 text-storm ml-2">
                {bsbError.message}
              </div>
            )
          })}
      </div>

      <div className="input-field">
        <Label htmlFor="accountNumber">Account number</Label>

        <input
          type="text"
          id="accountNumber"
          className="input-text inverted"
          value={bankingData.accountNumber}
          onChange={evt => onBankingDataFieldsChange({ accountNumber: evt.target.value.replace(/\s/g, '') })}
          pattern="^\d{1,9}$"
          title="Up to 9 digits"
          required
        />
      </div>

      <FileUpload
        id="ProofOfBankDetails"
        description="Proof of bank details"
        uploadedFile={uiFields.ProofOfBankDetails}
        attachmentErrors={uiFields.attachmentErrors}
        onChange={onBankDetailsUpload}
        requestStatus={bankDetailsRequestStatus}
        acceptedFileTypes="image/png, image/jpeg, image/jpg, application/pdf"
      >
        {bankDetailsHasChanged ? (
          <p className="mt-6 alert warning rounded-br-none">Please provide us with an updated proof of bank details.</p>
        ) : (
          <>
            <div>
              <p className="mt-6 alert info rounded-br-none">
                Please provide 1 proof of bank details in 1 of the following 3 formats:
              </p>

              <ul className="list-disc list-inside ml-4 my-6">
                <li>Pre-printed bank deposit slip</li>
                <li>Bank statement header</li>
                <li>Bank information in PDF format on company letterhead signed by management</li>
              </ul>
            </div>
          </>
        )}
      </FileUpload>

      <fieldset id="dgrStatus">
        <Label tooltipContent="If unsure about you DGR endorsement, please check with your tax advisor or contact the ATO.">
          Which of the following statements applies to your Deductible Gift Registration (DGR) status with the
          Australian Tax Office (ATO)?
        </Label>

        <ul className="radio-options">
          <li>
            <label htmlFor="dgrStatus1" className="inverted">
              <input
                type="radio"
                className="radio"
                id="dgrStatus1"
                value="NONE"
                name="dgrStatus"
                checked={bankingData.dgrStatus === 'NONE'}
                onChange={(evt: any) => {
                  if (evt.target.value) {
                    onBankingDataFieldsChange({ dgrStatus: evt.target.value })
                  }
                }}
                required
              />
              My organisation doesn&apos;t have the DGR endorsement
            </label>
          </li>

          <li>
            <label htmlFor="dgrStatus2" className="inverted">
              <input
                type="radio"
                className="radio"
                id="dgrStatus2"
                value="UNRESTRICTED"
                name="dgrStatus"
                checked={bankingData.dgrStatus === 'UNRESTRICTED'}
                onChange={(evt: any) => {
                  if (evt.target.value) {
                    onBankingDataFieldsChange({ dgrStatus: evt.target.value })
                  }
                }}
                required
              />
              My organisation has unrestricted DGR endorsement with the ATO
            </label>
          </li>

          <li>
            <label htmlFor="dgrStatus3" className="inverted">
              <input
                type="radio"
                className="radio"
                id="dgrStatus3"
                value="RESTRICTED"
                name="dgrStatus"
                checked={hasRestrictedDgr}
                onChange={(evt: any) => {
                  if (evt.target.value) {
                    onBankingDataFieldsChange({ dgrStatus: evt.target.value })
                  }
                }}
                required
              />
              My organisation has restricted DGR endorsement with the ATO
            </label>
          </li>
        </ul>

        {hasRestrictedDgr && (
          <div className="mt-6">
            <Label>
              Can you please confirm the funds raised through {displayName} will be spent exclusively on DGR related
              activities?
            </Label>

            <ul className="radio-options">
              <li>
                <label htmlFor="subDgrStatus1" className="inverted">
                  <input
                    type="radio"
                    className="radio inline-block mr-md"
                    id="subDgrStatus1"
                    value="RESTRICTED_DGR_ACTIVITIES_YES"
                    name="subDgrStatus"
                    checked={bankingData.dgrStatus === 'RESTRICTED_DGR_ACTIVITIES_YES'}
                    onChange={(evt: any) => {
                      if (evt.target.value) {
                        onBankingDataFieldsChange({ dgrStatus: evt.target.value })
                      }
                    }}
                    required
                  />
                  Yes
                </label>
              </li>

              <li>
                <label htmlFor="subDgrStatus2" className="inverted">
                  <input
                    type="radio"
                    className="radio inline-block mr-md"
                    id="subDgrStatus2"
                    value="RESTRICTED_DGR_ACTIVITIES_NO"
                    name="subDgrStatus"
                    checked={bankingData.dgrStatus === 'RESTRICTED_DGR_ACTIVITIES_NO'}
                    onChange={(evt: any) => {
                      if (evt.target.value) {
                        onBankingDataFieldsChange({ dgrStatus: evt.target.value })
                      }
                    }}
                    required
                  />
                  No
                </label>
              </li>
            </ul>
          </div>
        )}
      </fieldset>
    </Accordion>
  )
}
