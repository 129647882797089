import React, { FunctionComponent } from 'react'
import { TermsAndConditionsVICShort } from '../../../pages/terms-and-conditions/schemes/TermsAndConditionsVICShort'
import { Accordion } from '../../shared'

type Props = {
  hasAcceptedTos: boolean
  onHasAcceptedTosChange: (hasAcceptedTos: boolean) => void
}

export const AcceptVICTos: FunctionComponent<Props> = ({ hasAcceptedTos, onHasAcceptedTosChange }) => {
  return (
    <Accordion title="Terms and conditions">
      <div className="prose dark:prose-invert">
        <TermsAndConditionsVICShort />
      </div>

      <div>
        <a href="/tos" target="_blank" className="font-bold underline block mb-6" rel="noreferrer">
          Click here to read the full terms and conditions
        </a>

        <div className="flex items-center">
          <input
            id="tos"
            type="checkbox"
            className="checkbox mr-2"
            checked={hasAcceptedTos}
            onChange={evt => onHasAcceptedTosChange(evt.target.checked)}
            required
          />
          <label className="flex-auto select-none" htmlFor="tos">
            I have read and agree to the terms and conditions
          </label>
        </div>
      </div>
    </Accordion>
  )
}
