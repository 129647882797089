import { logError } from '@tomra/datadog-browser-logging'
import React, { FormEvent, FunctionComponent, useState } from 'react'
import { THEME } from '../../../lib'
import { getDonationPartnerDetails } from '../../../services'
import { donationPartnerIdIsValid } from './donationPartner'
const { supportEmail } = THEME

type Props = {
  onConfirmDetails: (donationPartnerDetails: DonationPartnerDetails) => Promise<void>
}

export const DonationPartnerForm: FunctionComponent<Props> = ({ onConfirmDetails }) => {
  const [status, setStatus] = useState<RequestStatus>('initial')
  const [donationPartnerId, setDonationPartnerId] = useState('')
  const [donationPartnerDetails, setDonationPartnerDetails] = useState<DonationPartnerDetails | null>()
  const [donationPartnerIdValid, setDonationPartnerIdValid] = useState(false)

  const fetchDonationPartnerDetails = async (e: FormEvent) => {
    e.preventDefault()

    try {
      setStatus('loading')
      const details = await getDonationPartnerDetails(donationPartnerId)
      setDonationPartnerDetails(details)
      setStatus('initial')
    } catch (error: any) {
      if (error.status === 412) {
        setStatus('preConditionFailed')
      } else if (error.status === 404) {
        setStatus('notFound')
      } else if (error.status === 409) {
        setStatus('conflict')
      } else {
        logError(new Error('Unable to fetch donation partner details'), error)
        setStatus('failed')
      }
    }
  }

  const reset = () => {
    setDonationPartnerDetails(null)
  }

  const donationPartnerIdIsNotValid = donationPartnerId.length === 11 && !donationPartnerIdValid

  return (
    <div className="max-w-screen-sm mx-auto card p-4">
      {donationPartnerDetails ? (
        <div className="flex flex-col space-y-6">
          <h1 className="text-2xl">Please verify</h1>

          <div className="input-field">
            <label className="label" htmlFor="donationPartnerId">
              Donation Partner ID
            </label>
            <input
              id="donationPartnerId"
              type="text"
              className="input-text cursor-not-allowed"
              value={donationPartnerDetails.donationPartnerId}
              readOnly
            />
          </div>
          <div className="input-field">
            <label className="label" htmlFor="legalName">
              Legal Name
            </label>
            <input
              id="legalName"
              type="text"
              className="input-text cursor-not-allowed"
              readOnly
              value={donationPartnerDetails.legalName}
            />
          </div>
          <div className="input-field">
            <label className="label" htmlFor="businessRegistrationNumber">
              Business Registration Number
            </label>
            <input
              id="businessRegistrationNumber"
              type="text"
              className="input-text cursor-not-allowed"
              value={donationPartnerDetails.businessRegistrationNumber}
              readOnly
            />
          </div>

          <div className="flex space-x-4 justify-end">
            <button type="button" className="btn" onClick={reset}>
              No, go back
            </button>
            <button type="button" className="btn primary" onClick={() => onConfirmDetails(donationPartnerDetails)}>
              Yes, this is correct
            </button>
          </div>
        </div>
      ) : (
        <>
          <h1 className="text-2xl mb-4">Please enter your Donation Partner ID</h1>

          <form className="flex flex-col space-y-4 items-start" onSubmit={fetchDonationPartnerDetails}>
            <div className="w-full input-field">
              <label htmlFor="donationPartnerId" className="label">
                Donation Partner ID
              </label>

              <input
                id="donationPartnerId"
                type="text"
                className="input-text"
                value={donationPartnerId}
                maxLength={11}
                onChange={({ target }) => {
                  const value = target.value
                  setDonationPartnerId(value)
                  setDonationPartnerIdValid(donationPartnerIdIsValid(value))
                }}
                required
              />

              <div className="text-sm ml-4">{donationPartnerId.length} / 11</div>
            </div>

            {status === 'loading' ? (
              <div className="loading-infinity w-8" />
            ) : (
              <button
                type="submit"
                className="btn primary"
                aria-disabled={!donationPartnerId || !donationPartnerIdValid}
              >
                Get details
              </button>
            )}

            {donationPartnerIdIsNotValid && (
              <div className="alert warning">
                The Donation Partner ID is not valid. Are you sure you entered the correct ID?
              </div>
            )}

            {status === 'notFound' && (
              <div className="alert warning">
                We couldn&apos;t find any donation partners with this id. Are you sure you entered the correct number?
              </div>
            )}

            {status === 'conflict' && (
              <div className="alert warning">
                The Donation Partner you entered is already in use with another e-mail. Please contact us at{' '}
                <a className="link" href={`mailto:${supportEmail}`}>
                  {supportEmail}
                </a>{' '}
                if you think this is a mistake.
              </div>
            )}

            {status === 'preConditionFailed' && (
              <div className="alert warning">
                Your Donation Partner registration with CDS VIC is incomplete. Please complete the registration and
                return to us.
              </div>
            )}

            {status === 'failed' && <div className="alert danger">Something went wrong. Please try again.</div>}
          </form>
        </>
      )}
    </div>
  )
}
